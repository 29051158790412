// import constants
import { cms_api_url } from '../constants';

export default function (query) {
	return fetch(cms_api_url, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({ query })
	})
		.then(res => res.json())
		.then(res => ({ data: res.data }))
		.catch(() => {});
}
