import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
// @import styles
import '../css/sections/testimonials.scss';
// import utils
import cms from '../utils/cms';

const query = `{
  testimonials{
    name
    testimonial
  }
}`;

const Slide = ({ name, testimonial }) => {
	return (
		<div className="slide">
			<div>
				<p>{testimonial}</p>
				<strong>{name}</strong>
			</div>
		</div>
	);
};

const Testimonials = () => {
	const [data, setdata] = useState(null);

	useEffect(() => {
		cms(query).then(data => {
			setdata(data.data.testimonials);
		});
	}, []);

	const settings = {
		dots: false,
		arrows: false,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 980,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 680,
				settings: {
					slidesToShow: 1
				}
			}
		]
	};

	return (
		<section id="ervaringen" className="blockSection">
			<div className="gridColumn">
				<h2 className="sectionTitle">ervaringen</h2>
				<div className="testimonialCarousel">
					{data && (
						<Slider {...settings}>
							{data.map((item, i) => {
								return (
									<Slide
										name={item.name}
										key={`testimonial_${i}`}
										testimonial={item.testimonial}
									/>
								);
							})}
						</Slider>
					)}
				</div>
			</div>
		</section>
	);
};

export default Testimonials;
