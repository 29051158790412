import React, { useState, useEffect } from 'react';
// @import components
import Accordian from '../components/accordian';
// @import styles
import '../css/sections/faq.scss';
// @import images
import faqImage from '../images/faq.png';
// import utils
import cms from '../utils/cms';

// FaqPartners: faqsCheqi(where: {faqCategory: cheqi_partners}){
//   question
//   answer
//   faqCategory
// }

const query = `{
	FaqCheqi: faqsCheqi(where: {faqCategory: cheqi_app}){
    question
    answer
    faqCategory
  }
  FaqRecieveCheqi: faqsCheqi(where: {faqCategory: recieve_cheqi}){
    question
    answer
    faqCategory
  }
}`;

const FaqTypes = [
	// { title: 'Zakelijk', type: 'FaqPartners' },
	{ title: 'Geldkaart ontvangen', type: 'FaqRecieveCheqi' },
	{ title: 'Geldkaart versturen', type: 'FaqCheqi' }
];

const Faq = () => {
	const [faqType, setfaqType] = useState('FaqRecieveCheqi');
	const [data, setdata] = useState(null);

	useEffect(() => {
		cms(query).then(data => {
			setdata(data.data);
		});
	}, []);

	console.log(data && data[faqType]);

	return (
		<div id="vragen" className="blockSection grayBackground">
			<div className="gridColumn">
				<h2 className="sectionTitle">veelgestelde vragen</h2>
				<div className="faqWrapper">
					<div className="imageWrapper">
						<img src={faqImage} alt="" />
					</div>
					<div className="accordianWrapper">
						<ul className="accordianTabs">
							{FaqTypes.map((item, index) => {
								return (
									<li key={`li_${index}`}>
										<button
											onClick={() => setfaqType(item.type)}
											className={faqType === item.type ? 'active' : ''}
										>
											{item.title}
										</button>
									</li>
								);
							})}
						</ul>
						{data && (
							<div className="accordianList">
								{data[faqType].map((faq, i) => (
									<Accordian
										key={`${faq.faqCategory}_${i}`}
										title={faq.question}
										desc={faq.answer}
									/>
								))}
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Faq;
