export const appStoreUrl =
	'http://itunes.apple.com/nl/app/cheqi/id1500603640?mt=8';

export const playStoreUrl =
	'https://play.google.com/store/apps/details?id=com.cheqi.cheqiapp';

export const cms_api_url =
	'https://api-eu-central-1.graphcms.com/v2/cknya5sluarg001wd382nbx06/master';

let appurl;
switch (window.location.hostname) {
	case 'localhost':
		appurl = 'https://accept.cheqi.app/';
		break;
	case 'accept.cheqi.nl':
		appurl = 'https://accept.cheqi.app/';
		break;
	case 'cheqi.nl':
		appurl = 'https://cheqi.app/';
		break;
	default:
		appurl = 'https://cheqi.app/';
}

export const app_url = appurl;
