// @import packages
import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { Route, Redirect, withRouter, Switch } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Layout from './components/layout';
// @import styles
import './App.scss';

const tagManagerArgs = {
	gtmId: 'GTM-MC37KRG'
};

const App = props => {
	const { routes, location = { pathname: '/', key: '' } } = props;
	const currentKey = (location && location.key) || '';
	const timeout = { enter: 400, exit: 200 };

	useEffect(() => {
		TagManager.initialize(tagManagerArgs);
	}, []);

	return (
		<Layout>
			<TransitionGroup component="main" className="page-main">
				<CSSTransition
					key={currentKey}
					timeout={timeout}
					classNames="fade"
					appear
				>
					<section className="page-main-inner">
						<Switch location={location}>
							{routes.map((route, index) => {
								return (
									<Route
										key={index}
										path={route.path}
										exact={route.exact}
										render={props => {
											return React.createElement(route.component, {
												...props
											});
										}}
									/>
								);
							})}
							<Redirect from="*" to="/404" />
						</Switch>
					</section>
				</CSSTransition>
			</TransitionGroup>
		</Layout>
	);
};

export default withRouter(App);
