import React from 'react';
// @import components
import Faq from '../sections/Faq';
import HowItWorks from '../sections/HowItWorks';
// import HeroBanner from '../sections/HeroBanner';
import Testimonials from '../sections/Testimonials';
// @import styles
import '../css/pages/home.scss';

const Home = () => {
	return (
		<div className="homeWrapper">
			{/* <HeroBanner /> */}
			<div className="contentWrapper">
				<h1>Tijdelijk niet beschikbaar</h1>
				<p>
					Er wordt gewerkt aan een vernieuwing van Cheqi. Op dit moment is het
					daarom niet mogelijk om geldkaarten te versturen.
				</p>
				<p>
					Heb je zelf een geldkaart ontvangen? Die kun je natuurlijk nog steeds
					ophalen. Volg hiervoor de stappen op de geldkaart die je hebt
					gekregen.
				</p>
			</div>
			<HowItWorks />
			<Testimonials />
			<Faq />
		</div>
	);
};

export default Home;
