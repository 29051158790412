import React from 'react';
import { Redirect } from 'react-router-dom';
import { isMobile, isAndroid, isIOS } from 'react-device-detect';
import { appStoreUrl, playStoreUrl } from '../constants';

const App = () => {
	let url;
	if (isMobile) {
		if (isIOS) {
			url = appStoreUrl;
		}
		if (isAndroid) {
			url = playStoreUrl;
		}
		window.location.href = url;
		return <Redirect to="/" />;
	} else {
		url = '/';
		return <Redirect to={url} />;
	}
};

export default App;
