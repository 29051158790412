import React from 'react';
import { Link } from 'react-router-dom';
// @import styles
import '../css/components/logo.scss';
// @import logo svg
import CheqiLogo from '../images/cheqi-postnl.png';

const Logo = () => {
	return (
		<Link to="/" className="logoWrapper">
			<img src={CheqiLogo} className="logo" alt="" />
		</Link>
	);
};

export default Logo;
