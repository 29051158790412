import React, { useState, useRef } from 'react';
import gsap from 'gsap';
// @import styles
import '../css/components/accordian.scss';
// @import svg
import { ReactComponent as Add } from '../svg/add.svg';

const Accordian = ({ title, desc }) => {
	const [isOpen, setOpen] = useState(false);
	const accord = useRef(null);
	function handleAccordian() {
		if (!isOpen) {
			gsap.set(accord.current, { height: 'auto' });
			gsap.from(accord.current, 0.2, { height: 0 });
			gsap.to(accord.current, 0.2, { paddingBottom: 15 });
			setOpen(!isOpen);
		} else {
			gsap.to(accord.current, 0.2, { height: 0, paddingBottom: 0 });
			setOpen(!isOpen);
		}
	}
	return (
		<div className={`accordian`}>
			<div className="wrapper" onClick={handleAccordian}>
				<Add className={`${isOpen ? 'active' : ''}`} />
				<strong>{title}</strong>
			</div>
			<div ref={accord} className="details">
				{desc}
			</div>
		</div>
	);
};

export default Accordian;
