import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import ReactMarkdown from 'react-markdown';
import { useParams, Redirect } from 'react-router';
import { isMobile, isAndroid, isIOS } from 'react-device-detect';
// import components
import App from './App';
import PageNotFound from './PageNotFound';
import Faq from '../sections/Faq';
import HowItWorks from '../sections/HowItWorks';
import Testimonials from '../sections/Testimonials';
// import utils
import cms from '../utils/cms';
import { appStoreUrl, playStoreUrl } from '../constants';
// @import images
import appstore from '../images/ios.png';
import playstore from '../images/googleplay.png';

const query = slug =>
	`{
    chapter(where: {slug: "${slug}"}) {
      slug
      title
      content
      metaTitle
      metaDescription
			addHowitworks
			addTestimonials
			addFaq
			addAppStoreUrl
    }
  }`;

const Chapters = () => {
	const { pagename } = useParams();
	const [data, setdata] = useState(null);

	useEffect(() => {
		if (pagename !== '404' && pagename !== 'app') {
			cms(query(pagename)).then(data => {
				setdata(data.data.chapter === null ? 'redirect' : data.data.chapter);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (pagename === '404') {
		return <PageNotFound />;
	}

	if (data === 'redirect') {
		return <Redirect from="*" to="/404" />;
	}

	if (pagename === 'app') {
		return <App />;
	}

	return (
		<>
			<div className="contentWrapper">
				{data && (
					<>
						{data.metaDescription && (
							<Helmet>
								<meta
									name="description"
									content="Cheqi is de leukste manier om geld kado te doen of kleine bedragen te betalen"
								/>
							</Helmet>
						)}
						<h1>{data.title}</h1>
						<ReactMarkdown children={data.content} />
						{data?.addAppStoreUrl && (
							<div className="appstoreButtons">
								{isMobile ? (
									<div className="row">
										{isIOS && (
											<a
												href={appStoreUrl}
												className="appstore"
												target="_blank"
												rel="noopener noreferrer"
											>
												<img alt="appstore" src={appstore} />
											</a>
										)}
										{isAndroid && (
											<a
												href={playStoreUrl}
												className="playstore"
												target="_blank"
												rel="noopener noreferrer"
											>
												<img alt="playstore" src={playstore} />
											</a>
										)}
									</div>
								) : (
									<div className="row">
										<a
											href={appStoreUrl}
											className="appstore"
											target="_blank"
											rel="noopener noreferrer"
										>
											<img alt="appstore" src={appstore} />
										</a>
										<a
											href={playStoreUrl}
											className="playstore"
											target="_blank"
											rel="noopener noreferrer"
										>
											<img alt="playstore" src={playstore} />
										</a>
									</div>
								)}
							</div>
						)}
					</>
				)}
			</div>
			{data?.addHowitworks && <HowItWorks />}
			{data?.addTestimonials && <Testimonials />}
			{data?.addFaq && <Faq />}
		</>
	);
};

export default Chapters;
