import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useMenuActive = ref => {
	const location = useLocation();

	useEffect(() => {
		document.querySelector('body').addEventListener('scroll', scrollListener);
		if (location.pathname !== '/') {
			ref.current.classList.remove('active');
		}
		return () => {
			document
				.querySelector('body')
				.removeEventListener('scroll', scrollListener);
		};
	});

	function scrollListener() {
		if (location.pathname === '/') {
			// let fromTop = window.scrollY;
			let section = document.querySelector(`#${ref.current.dataset.id}`);
			if (section) {
				const boundings = section.getBoundingClientRect();
				if (
					boundings.top <= window.outerHeight / 1.5 &&
					boundings.bottom >= window.outerHeight / 1.5
				) {
					ref.current.classList.add('active');
				} else {
					ref.current.classList.remove('active');
				}
			}

			// if (section) {
			// 	if (
			// 		section.offsetTop - window.outerHeight / 1.5 <= fromTop &&
			// 		section.offsetTop + section.offsetHeight - window.outerHeight / 1.5 >
			// 			fromTop
			// 	) {
			// 		ref.current.classList.add('active');
			// 	} else {
			// 		ref.current.classList.remove('active');
			// 	}
			// }
		}
	}
};
