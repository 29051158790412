import React, { useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
// @import constants
// import { app_url } from '../constants';
// @import utils
import ScrollToHelper from '../utils/scrollTo';
import { useMenuActive } from '../utils/handleMenuActive';
// @import styles
import '../css/components/navMenu.scss';

const Item = ({ name, sectionID, active }) => {
	const location = useLocation();
	const history = useHistory();
	const ref = useRef();

	useMenuActive(ref);

	function handleClick() {
		if (location.pathname !== '/') {
			history.push('/');
			setTimeout(() => {
				ScrollToHelper(
					sectionID,
					sectionID === 'download' ? 'start' : 'center'
				);
			}, 100);
		} else {
			ScrollToHelper(sectionID, sectionID === 'download' ? 'start' : 'center');
		}
	}

	return (
		<span
			ref={ref}
			data-id={sectionID}
			onClick={handleClick}
			className={active ? 'active' : ''}
		>
			{name}
		</span>
	);
};

const NavMenu = () => {
	// const location = useLocation();
	return (
		<nav className="headerMenu">
			<ul>
				{/* banner */}
				{/* <li>
					<Item
						sectionID="download"
						name="home"
						active={location.pathname === '/'}
					/>
				</li> */}
				<li>
					{/* how it works */}
					<Item sectionID="hoe-het-werkt" name="hoe het werkt" />
				</li>
				<li>
					{/* testimonials */}
					<Item sectionID="ervaringen" name="ervaringen" />
				</li>
				{/* your cheqi */}
				{/* <li>
					<a href={`${app_url}create-cheqi`}>jouw geldkaarten</a>
				</li> */}
				<li>
					{/* faqs */}
					<Item sectionID="vragen" name="vragen" />
				</li>
				<li>
					{/* contact */}
					<Item sectionID="contact" name="contact" />
				</li>
				{/* <li>
					<a
						href="/partners"
						className={location.pathname === '/partners' ? 'active' : ''}
					>
						partners
					</a>
				</li> */}
			</ul>
		</nav>
	);
};

export default NavMenu;
