import Home from './pages/Home';
import Chapters from './pages/Chapters';

const routes = [
	{
		path: '/',
		exact: true,
		name: 'Home',
		component: Home
	},
	{
		path: '/:pagename',
		name: 'Over Cheqi',
		exact: true,
		isInFooter: true,
		component: Chapters
	}
];

export default routes;

export const FooterLinks = [
	{
		path: '/over-cheqi',
		name: 'Over Cheqi'
	},
	{
		path: '/gebruiksvoorwaarden',
		name: 'Gebruiksvoorwaarden'
	},
	{
		path: '/privacyverklaring',
		name: 'Privacyverklaring'
	}
	// {
	// 	path: '/werken-bij-cheqi',
	// 	name: 'Werken bij Cheqi'
	// },
	// {
	// 	path: '/pers',
	// 	name: 'Pers / media'
	// }
	// {
	// 	path: '/partners',
	// 	name: 'Partners'
	// },
	// {
	// 	path: '/download',
	// 	name: 'Download'
	// },
	// {
	// 	path: '/zakelijk',
	// 	name: 'Zakelijk'
	// },
	// {
	// 	path: 'https://blog.cheqi.nl/',
	// 	name: 'Blog',
	// 	isExrernal: true
	// }
];
