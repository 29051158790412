import React from 'react';
import { Helmet } from 'react-helmet';
import TagManager from 'react-gtm-module';
import { useLocation } from 'react-router-dom';
// @import components
import Header from './includes/header';
import Footer from './includes/footer';
import { usePageChange } from '../utils/detectPageChange';

const tagManagerArgs = {
	gtmId: 'GTM-MZVRS3T'
};

TagManager.initialize(tagManagerArgs);

const Layout = props => {
	usePageChange();

	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);

	return (
		<div
			className={`mainContainer ${
				searchParams.get('mode') === 'webview' ? 'webviewMode' : ''
			}`}
		>
			<Helmet
				defaultTitle="Cheqi maakt geld geven simpel!"
				titleTemplate="%s | Cheqi"
			>
				<link
					href="https://fonts.googleapis.com/css?family=DM+Sans:400,700&display=swap"
					rel="stylesheet"
				/>
				{searchParams.get('mode') !== 'webview' && (
					<link
						rel="stylesheet"
						type="text/css"
						charset="UTF-8"
						href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
					/>
				)}
				{searchParams.get('mode') !== 'webview' && (
					<link
						rel="stylesheet"
						type="text/css"
						href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
					/>
				)}
			</Helmet>
			{searchParams.get('mode') !== 'webview' && (
				<Header location={location.pathname} />
			)}
			<main className="wrapper">{props.children}</main>
			{searchParams.get('mode') !== 'webview' && <Footer />}
		</div>
	);
};

export default Layout;
