import React from 'react';
import { NavLink } from 'react-router-dom';
// import { isMobile, isAndroid, isIOS } from 'react-device-detect';
import { FooterLinks } from '../../routes';
// @import constants
// import { appStoreUrl, playStoreUrl } from '../../constants';
// @import styles
import '../../css/components/footer.scss';
// @import images
// import appstore from '../../images/ios.png';
// import playstore from '../../images/googleplay.png';

const Footer = () => {
	return (
		<>
			<footer id="contact" className="footer">
				<div className="gridColumn">
					{/* <div className="column">
						<strong>Cheqi maakt geld geven simpel!</strong>
						<p>
							Cheqi is een cadeau-app waarmee je simpel geld verstuurt. Kies het
							bedrag dat je wilt geven, voeg vervolgens een kaart en een cadeau
							toe en laat het Cheqi bezorgen. De postbode bezorgt het Cheqi op
							ieder adres in Nederland.
						</p>

						{isMobile ? (
							<div className="row">
								{isIOS && (
									<a
										href={appStoreUrl}
										className="appstore"
										target="_blank"
										rel="noopener noreferrer"
									>
										<img alt="appstore" src={appstore} />
									</a>
								)}
								{isAndroid && (
									<a
										href={playStoreUrl}
										className="playstore"
										target="_blank"
										rel="noopener noreferrer"
									>
										<img alt="playstore" src={playstore} />
									</a>
								)}
							</div>
						) : (
							<div className="row">
								<a
									href={appStoreUrl}
									className="appstore"
									target="_blank"
									rel="noopener noreferrer"
								>
									<img alt="appstore" src={appstore} />
								</a>
								<a
									href={playStoreUrl}
									className="playstore"
									target="_blank"
									rel="noopener noreferrer"
								>
									<img alt="playstore" src={playstore} />
								</a>
							</div>
						)}
					</div> */}

					<div className="column">
						<strong>Contact</strong>
						<p>
							Vragen, opmerkingen, idee&euml;n? We horen graag van je. Je kunt
							ons altijd bereiken via{' '}
							<a className="link" href="mailto:help@cheqi.nl">
								help@cheqi.nl
							</a>
							.
						</p>
					</div>
				</div>
			</footer>
			<span className="seprator" />
			<div className="copyrights">
				<div className="gridColumn">
					<div>
						{FooterLinks.map(route =>
							route.isExrernal ? (
								<a
									key={route.name}
									href={route.path}
									target="_blank"
									rel="noopener noreferrer"
								>
									{route.name}
								</a>
							) : (
								<NavLink
									exact
									to={route.path}
									key={route.name}
									activeClassName="active"
								>
									{route.name}
								</NavLink>
							)
						)}
					</div>
					<span>Copyright &copy; {new Date().getFullYear()} Cheqi B.V.</span>
				</div>
			</div>
		</>
	);
};

export default Footer;
