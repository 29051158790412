import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
// @import styles
import '../css/sections/howItWorks.scss';
// import utils
import cms from '../utils/cms';

const query = `{
	sections {
    title
    description
    sectionImage{
      url
    }
  }
}`;

const Slide = ({ image, title, desc }) => {
	return (
		<div className="slide">
			<img src={image} alt={title} />
			<div>
				<h3>{title}</h3>
				<p>{desc}</p>
			</div>
		</div>
	);
};

const HowItWorks = () => {
	const [data, setdata] = useState(null);

	useEffect(() => {
		cms(query).then(data => {
			setdata(data.data.sections);
		});
	}, []);

	const settings = {
		dots: false,
		arrows: false,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 980,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 680,
				settings: {
					slidesToShow: 1
				}
			}
		]
	};

	return (
		<section id="hoe-het-werkt" className="blockSection themeBackgound">
			<div className="gridColumn">
				<h2 className="sectionTitle">hoe het werkt</h2>
				<div className="sliderWrapper">
					{data && (
						<Slider {...settings}>
							{data.map((item, i) => {
								return (
									<Slide
										title={item.title}
										key={`section_${i}`}
										desc={item.description}
										image={item.sectionImage.url}
									/>
								);
							})}
						</Slider>
					)}
				</div>
			</div>
		</section>
	);
};

export default HowItWorks;
